import '@mrhenry/wp--bugsnag-config';

import { MrTabs, MrCarousel, MrMatchesValue, MrDisplayValue } from '@mrhenry/wp--mr-interactive';

import './modules/copy';
import './modules/gdpr-cookie';
import './modules/load-more';
import './modules/locations-map';
import './modules/map';
import './modules/mr-accordion';
import './modules/mr-input-sink';
import './modules/mr-modal';
import './modules/mr-navigation-overlay';
import './modules/ratings-form';

import './helpers/page-anchor.js';

customElements.define( 'mr-carousel', MrCarousel );
customElements.define( 'mr-display-value', MrDisplayValue );
customElements.define( 'mr-matches-value', MrMatchesValue );
customElements.define( 'mr-tabs', MrTabs );
