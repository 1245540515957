export class MrTabs extends HTMLElement {
	static #keys = {
		end: 35,
		home: 36,
		left: 37,
		up: 38,
		right: 39,
		down: 40,
		delete: 46,
	};

	// Add or subtract depending on key pressed
	static #direction: Record<number, number> = {
		37: -1,
		38: -1,
		39: 1,
		40: 1,
	};

	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );
		this.addEventListener( 'keydown', this.#keydownHandler );
		this.addEventListener( 'keyup', this.#keyupHandler );
		this.addEventListener( 'beforematch', this.#beforeMatchHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );
		this.removeEventListener( 'keydown', this.#keydownHandler );
		this.removeEventListener( 'keyup', this.#keyupHandler );
		this.removeEventListener( 'beforematch', this.#beforeMatchHandler );
	}

	#clickHandler = ( event: Event ) => {
		if ( !event.target ) {
			return;
		}

		const target = event.target;
		if ( !( target instanceof HTMLElement ) ) {
			return;
		}

		const tab = target.closest( '[role="tab"]' );
		if ( !tab || !( tab instanceof HTMLElement ) ) {
			return;
		}

		this.activateTab( tab, false );
	};

	#focusHandler = ( event: FocusEvent ) => {
		if ( !event.target ) {
			return;
		}

		const target = event.target;
		if ( !( target instanceof HTMLElement ) ) {
			return;
		}

		setTimeout( () => {
			this.checkTabFocus( target );
		}, 10 );
	};

	// Handle keydown on tabs
	#keydownHandler( event: KeyboardEvent ) {
		const key = event.keyCode;

		switch ( key ) {
			case MrTabs.#keys.end: {
				event.preventDefault();
				// Activate last tab
				const tabs = this.querySelectorAll<HTMLElement>( '[role="tab"]' );
				const tab = tabs[tabs.length - 1];
				if ( !tab ) {
					return;
				}

				this.activateTab( tab );
			}

				break;
			case MrTabs.#keys.home: {
				event.preventDefault();
				// Activate first tab
				const tabs = this.querySelectorAll<HTMLElement>( '[role="tab"]' );
				const tab = tabs[0];
				if ( !tab ) {
					return;
				}

				this.activateTab( tab );
			}

				break;

			// Up and down are in keydown
			// because we need to prevent page scroll >:)
			case MrTabs.#keys.up:
			case MrTabs.#keys.down:
				this.determineOrientation( event );
				break;
		}
	}

	// Handle keyup on tabs
	#keyupHandler( event: KeyboardEvent ) {
		const key = event.keyCode;

		switch ( key ) {
			case MrTabs.#keys.left:
			case MrTabs.#keys.right:
				this.determineOrientation( event );
				break;
		}
	}

	#beforeMatchHandler = ( event: Event ): void => {
		if ( !event.target ) {
			return;
		}

		if ( !( event.target instanceof HTMLElement ) ) {
			return;
		}

		const panel = event.target.closest( '[role="tabpanel"]' );
		if ( !panel || !panel.id ) {
			return;
		}

		const tab = this.querySelector( `[aria-controls="${panel.id}"]` );
		if ( !tab || !( tab instanceof HTMLElement ) ) {
			return;
		}

		this.activateTab( tab, false );
	};

	activateTab( tab: HTMLElement, setFocus = true ) {
		// Get the value of aria-controls (which is an ID)
		const controlsId = tab.getAttribute( 'aria-controls' );
		if ( !controlsId ) {
			return;
		}

		const controlsEl = document.getElementById( controlsId );
		if ( !controlsEl ) {
			return;
		}

		// Deactivate all other tabs
		this.deactivateTabs();

		// Remove tabindex attribute
		tab.removeAttribute( 'tabindex' );

		// Set the tab as selected
		tab.setAttribute( 'aria-selected', 'true' );

		// Remove hidden attribute from tab panel to make it visible
		controlsEl.removeAttribute( 'hidden' );
		controlsEl.setAttribute( 'tabindex', '0' );

		// Set focus when required
		if ( setFocus ) {
			tab.focus();
		}
	}

	deactivateTabs() {
		const tabs = this.querySelectorAll<HTMLElement>( '[role="tab"]' );
		for ( let t = 0; t < tabs.length; t++ ) {
			tabs[t].setAttribute( 'tabindex', '-1' );
			tabs[t].setAttribute( 'aria-selected', 'false' );
			tabs[t].removeEventListener( 'focus', this.#focusHandler );
		}

		const panels = this.querySelectorAll( '[role="tabpanel"]' );
		for ( let p = 0; p < panels.length; p++ ) {
			panels[p].setAttribute( 'hidden', 'until-found' );
			panels[p].removeAttribute( 'tabindex' );
		}
	}

	checkTabFocus( target : HTMLElement ) {
		if ( target !== document.activeElement ) {
			return;
		}

		this.activateTab( target, false );
	}

	// When a tablist's aria-orientation is set to vertical,
	// only up and down arrow should function.
	// In all other cases only left and right arrow function.
	determineOrientation( event : KeyboardEvent ) {
		const key = event.keyCode;
		const vertical = 'vertical' === this.getAttribute( 'aria-orientation' );

		if ( vertical && ( key === MrTabs.#keys.up || key === MrTabs.#keys.down ) ) {
			event.preventDefault();
			this.switchTabOnArrowPress( event );

			return;
		}

		if ( key === MrTabs.#keys.left || key === MrTabs.#keys.right ) {
			event.preventDefault();
			this.switchTabOnArrowPress( event );

			return;
		}
	}

	// Either focus the next, previous, first, or last tab
	// depending on key pressed
	switchTabOnArrowPress( event: KeyboardEvent ) {
		if ( !event.target ) {
			return;
		}

		const target = event.target;
		if ( !( target instanceof HTMLElement ) ) {
			return;
		}

		const pressed = event.keyCode;
		if ( !MrTabs.#direction[pressed] ) {
			return;
		}

		const tabs = Array.from( this.querySelectorAll<HTMLElement>( '[role="tab"]' ) );
		if ( !tabs.includes( target ) ) {
			return;
		}

		for ( let x = 0; x < tabs.length; x++ ) {
			tabs[x].addEventListener( 'focus', this.#focusHandler );
		}

		const nextIndex = tabs.indexOf( target ) + MrTabs.#direction[pressed];
		if ( nextIndex >= tabs.length ) {
			tabs[0].focus();

			return;
		} else if ( nextIndex < 0 ) {
			tabs[tabs.length - 1].focus();

			return;
		}

		tabs[nextIndex].focus();
	}

	focusFirstTab() {
		const tabs = this.querySelectorAll<HTMLElement>( '[role="tab"]' );
		tabs[0]?.focus();
	}

	focusLastTab() {
		const tabs = this.querySelectorAll<HTMLElement>( '[role="tab"]' );
		tabs[tabs.length - 1]?.focus();
	}
}
