export default function dynamicProperties( properties = {}, settings = {
	throttle: 64,
} ) {
	const EMITTER = document.createDocumentFragment();
	let cached = {};

	const addEventListener = ( ...args ) => {
		EMITTER.addEventListener( ...args );
	};

	const removeEventListener = ( ...args ) => {
		EMITTER.removeEventListener( ...args );
	};

	const dispatchEvent = ( ...args ) => {
		EMITTER.dispatchEvent( ...args );
	};

	const store = {
		addEventListener,
		removeEventListener,
		dispatchEvent,
	};

	const addProperty = ( key, getter, element = null ) => {
		if ( 'undefined' !== typeof store[key] ) {
			console.warn( key, 'is already defined in this property list' );

			return false;
		}

		Object.defineProperty( store, key, {
			enumerable: true,
			configurable: false,
			get: () => {
				if ( 'undefined' !== typeof cached[key] ) {
					return cached[key];
				}

				let value;
				if ( element ) {
					value = getter.call( element );
				} else {
					value = getter();
				}

				cached[key] = value;

				return cached[key];
			},
		} );

		return store;
	};

	Object.entries( properties ).forEach( ( [
		key,
		getter,
	] ) => {
		if ( 'function' === typeof getter ) {
			addProperty( key, getter );
		} else if ( 'string' !== typeof getter && getter.length ) {
			// Lazy array check
			addProperty( key, getter[0], getter[1] || window );
		}
	} );

	// Reset cache on resize
	let resizeTimeout;
	window.addEventListener( 'resize', () => {
		if ( resizeTimeout ) {
			clearTimeout( resizeTimeout );
		}

		resizeTimeout = setTimeout( () => {
			cached = {};
			resizeTimeout = undefined;

			const change = new Event( 'change' );
			store.dispatchEvent( change );
		}, settings.throttle );
	} );

	return Object.freeze( store );
}
