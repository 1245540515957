import { playAllAnimations } from '@mrhenry/wp--play-all-animations';

export class MrCarousel extends HTMLElement {
	static get observedAttributes(): Array<string> {
		return [
			'loop',
			'autoplay',
			'data-state',
			'data-interval',
		];
	}

	#currentIndex = 0;

	get value(): string {
		return `${this.#currentIndex + 1}/${this.childElementCount}`;
	}

	// #region In view
	#observer = new IntersectionObserver( ( entries ) => {
		for ( const entry of entries ) {
			if ( !entry || !entry.target ) {
				continue;
			}

			if ( entry.target !== this ) {
				continue;
			}

			if ( !this.autoplay ) {
				return;
			}

			if ( entry.isIntersecting ) {
				if ( window.matchMedia( '(prefers-reduced-motion: reduce)' ).matches ) {
					return;
				}

				this.play();
			} else {
				this.pause();
			}
		}
	} );
	// #endregion In view

	// #region Paused
	#paused = true;

	async play(): Promise<void> {
		if ( !this.#paused ) {
			return;
		}

		this.#resetTicker();

		this.#paused = false;
		this.setAttribute( 'aria-live', 'off' );
		this.dispatchEvent( new Event( 'play', bubbles ) );
	}

	async pause(): Promise<void> {
		const wasPaused = this.#paused;

		window.clearTimeout( this.#ticker );

		this.#paused = true;
		this.setAttribute( 'aria-live', 'polite' );

		if ( !wasPaused ) {
			this.dispatchEvent( new Event( 'pause', bubbles ) );
		}
	}

	get paused(): boolean {
		return this.#paused;
	}

	#resetTicker() {
		window.clearTimeout( this.#ticker );
		this.#ticker = window.setTimeout(
			this.#goToNextSlideDuringPlayback,
			this.interval
		);
	}
	// #endregion Paused

	#ticker: number = 0;

	// #region Hovered
	#isBeingHovered = false;

	#setIsBeingHovered = () => {
		this.#isBeingHovered = true;
	};

	#setIsNotBeingHovered = () => {
		this.#isBeingHovered = false;

		if ( !this.paused ) {
			this.#resetTicker();
		}
	};
	// #endregion Hovered

	// #region Focus In
	#hasFocus = false;

	#setHasFocus = () => {
		this.#hasFocus = true;
		this.setAttribute( 'aria-live', 'polite' );
	};

	#setDoesNotHaveFocus = () => {
		this.#hasFocus = false;

		if ( !this.paused ) {
			this.#resetTicker();
			this.setAttribute( 'aria-live', 'off' );
		}
	};
	// #endregion Focus In

	// #region Freeze
	#isFrozen = false;

	#freeze = () => {
		this.#isFrozen = true;
		this.setAttribute( 'aria-live', 'polite' );
	};

	#unfreeze = () => {
		this.#isFrozen = false;

		if ( !this.paused ) {
			this.#resetTicker();
			this.setAttribute( 'aria-live', 'off' );
		}
	};

	private get frozen(): boolean {
		return this.#isFrozen || this.#isBeingHovered || this.#hasFocus;
	}
	// #endregion Freeze

	// #region currentTime
	get currentTime(): number {
		return this.#currentIndex * this.interval;
	}

	set currentTime( value: number ) {
		const requestedIndex = Math.min(
			Math.max(
				0,
				Math.floor( value / this.interval )
			),
			this.childElementCount - 1
		);

		this.updateState( `go-to-index:${requestedIndex}` );
	}
	// #endregion currentTime

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();
	}

	connectedCallback(): void {
		// Default State
		if ( !this.state ) {
			this.state = '0';
		}

		if ( !this.interval ) {
			this.interval = 5000;
		}

		if ( window.matchMedia( '(prefers-reduced-motion: reduce)' ).matches ) {
			this.autoplay = false;
		}

		enforceRoleGroup( this );

		this.#observer.observe( this );

		this.addEventListener( 'mouseover', this.#setIsBeingHovered );
		this.addEventListener( 'mouseout', this.#setIsNotBeingHovered );
		this.addEventListener( 'focusin', this.#setHasFocus );
		this.addEventListener( 'focusout', this.#setDoesNotHaveFocus );
	}

	disconnectedCallback(): void {
		window.clearTimeout( this.#ticker );

		this.#observer.unobserve( this );

		this.removeEventListener( 'mouseover', this.#setIsBeingHovered );
		this.removeEventListener( 'mouseout', this.#setIsNotBeingHovered );
		this.removeEventListener( 'focusin', this.#setHasFocus );
		this.removeEventListener( 'focusout', this.#setDoesNotHaveFocus );
	}

	#goToNextSlideDuringPlayback = (): void => {
		if ( this.#paused ) {
			return;
		}

		if ( this.frozen ) {
			this.#resetTicker();

			return;
		}

		this.updateState( 'go-to-next' );
	};

	get autoplay(): boolean {
		return this.hasAttribute( 'autoplay' );
	}

	set autoplay( value: boolean ) {
		if ( value ) {
			this.setAttribute( 'autoplay', '' );
		} else {
			this.removeAttribute( 'autoplay' );
		}
	}

	get interval(): number {
		const _interval = parseInt( this.getAttribute( 'data-interval' ) || '5000', 10 );
		if ( Number.isNaN( _interval ) ) {
			return 5000;
		}

		return _interval;
	}

	set interval( value: number ) {
		if ( value ) {
			this.setAttribute( 'data-interval', value.toString() );
		} else {
			this.removeAttribute( 'data-interval' );
		}
	}

	// Attributes
	override setAttribute( attr: string, value: string ): void {
		if ( 'loop' === attr ) {
			if ( value ) {
				super.setAttribute( 'loop', value );
			} else {
				super.removeAttribute( 'loop' );
			}
		}

		if ( 'autoplay' === attr ) {
			if ( value ) {
				super.setAttribute( 'autoplay', value );
			} else {
				super.removeAttribute( 'autoplay' );
			}
		}

		super.setAttribute( attr, value );
	}

	override removeAttribute( attr: string ): void {
		super.removeAttribute( attr );
	}

	get state() :string {
		return this.getAttribute( 'data-state' ) || '';
	}

	set state( value: string ) {
		this.setAttribute( 'data-state', value );
	}

	get loop(): boolean {
		return this.hasAttribute( 'loop' );
	}

	set loop( value: boolean ) {
		if ( value ) {
			this.setAttribute( 'loop', '' );
		} else {
			this.removeAttribute( 'loop' );
		}
	}

	async goToSlide( newIndex:number, newSlide: HTMLElement ): Promise<void> {
		Array.from( this.children ).forEach( ( child ) => {
			child.setAttribute( 'data-slide', '' );
		} );

		newSlide.setAttribute( 'data-slide', 'current' );

		const nextIndex = adjustIndex( newIndex + 1, this.childElementCount - 1, this.loop );
		const nextSlide = maybeHTMLElement( this.children[nextIndex] );
		if ( nextSlide !== newSlide ) {
			nextSlide?.setAttribute( 'data-slide', 'next' );
		}

		const previousIndex = adjustIndex( newIndex - 1, this.childElementCount - 1, this.loop );
		const previousSlide = maybeHTMLElement( this.children[previousIndex] );
		if ( previousSlide !== newSlide ) {
			previousSlide?.setAttribute( 'data-slide', 'previous' );
		}

		if ( !this.paused ) {
			this.#resetTicker();
		}
	}

	async changeSlideState( oldIndex: number, targetIndex: number ): Promise<void> {
		if ( this.state === 'rotating' ) {
			return;
		}

		const newIndex = adjustIndex( targetIndex, this.childElementCount - 1, this.loop );
		if ( newIndex === oldIndex ) {
			this.pause();

			return;
		}

		await this.willChangeSlide( oldIndex, newIndex );

		const newSlide = maybeHTMLElement( this.children[newIndex] );
		if ( !newSlide ) {
			return;
		}

		try {
			Array.from( this.children ).forEach( ( child ) => {
				child.inert = true;
			} );
			newSlide.inert = false;
		} catch ( err ) {
			console.warn( err );
		}

		// Update state
		this.state = 'rotating';

		this.goToSlide( newIndex, newSlide );

		await playAllAnimations( this.animations( oldIndex, newIndex ) );

		await this.didChangeSlide( oldIndex, newIndex );

		// Update state
		this.#currentIndex = newIndex;
		this.state = newIndex.toString();

		this.dispatchEvent( new Event( 'change', bubbles ) );
		this.dispatchEvent( new Event( 'timeupdate', bubbles ) );
	}

	/**
	 * Update the state of the dialog
	 * @param  {string} directive The directive for the state machine
	 * @return
	 */
	async updateState( directive: string ): Promise<void> {
		// self healing stuff
		enforceRoleGroup( this );

		switch ( directive ) {
			case 'freeze':
				if ( !this.#isFrozen ) {
					this.#freeze();
				}
				break;
			case 'unfreeze':
				if ( this.#isFrozen ) {
					this.#unfreeze();
				}
				break;
			case 'go-to-next': {
				await this.changeSlideState( this.#currentIndex, this.#currentIndex + 1 );
				break;
			}
			case 'go-to-previous':
				await this.changeSlideState( this.#currentIndex, this.#currentIndex - 1 );
				break;
			default:
				const parts = directive.split( ':' );
				if ( parts.length !== 2 ) {
					return;
				}

				if ( parts[0] !== 'go-to-index' ) {
					return;
				}

				const targetIndex = parseInt( parts[1], 10 );
				if ( Number.isNaN( targetIndex ) ) {
					return;
				}

				await this.changeSlideState( this.#currentIndex, targetIndex );
				break;
		}
	}

	/**
	 * Called before transitioning to another slide
	 * Optionally implement this in your sub class
	 */
	async willChangeSlide( oldIndex: number, newIndex: number ): Promise<void> { // eslint-disable-line @typescript-eslint/no-unused-vars
		await Promise.resolve();
	}

	/**
	 * Called after transitioning to another slide
	 * Optionally implement this in your sub class
	 */
	async didChangeSlide( oldIndex: number, newIndex: number ): Promise<void> { // eslint-disable-line @typescript-eslint/no-unused-vars
		await Promise.resolve();
	}

	/**
	 * The animations to play and wait for when changing slides
	 * Optionally implement this in your sub class
	 * @return {Array<KeyframeEffect>} The KeyframeEffects to play
	 */
	animations( oldIndex: number, newIndex: number ): Array<KeyframeEffect> { // eslint-disable-line @typescript-eslint/no-unused-vars
		return [];
	}
}

function adjustIndex( targetIndex: number, maxValue: number, loop: boolean ): number {
	if ( loop && targetIndex > maxValue ) {
		return 0;
	}

	if ( !loop && targetIndex > maxValue ) {
		return maxValue;
	}

	if ( loop && targetIndex < 0 ) {
		return maxValue;
	}

	if ( !loop && targetIndex < 0 ) {
		return 0;
	}

	return targetIndex;
}

function enforceRoleGroup( element: HTMLElement ): void {
	element.childNodes.forEach( ( childNode ) => {
		if ( !( childNode instanceof HTMLElement ) ) {
			return;
		}

		childNode.setAttribute( 'role', 'group' );
	} );
}

function maybeHTMLElement( x: Node | null ): HTMLElement | null {
	if ( x && ( x instanceof HTMLElement ) ) {
		return x;
	}

	return null;
}

const bubbles = {
	bubbles: true,
};
