import Mapbox from '../helpers/mapbox';

class MrMap extends HTMLElement {
	connectedCallback() {
		if ( !( 'IntersectionObserver' in window ) ) {
			return;
		}

		// Wait for first (or next) frame render
		window.requestAnimationFrame( () => {
			this._observer = new IntersectionObserver( ( entries ) => {
				for ( let i = 0; i < entries.length; i += 1 ) {
					const entry = entries[i];

					if ( !entry || !entry.target ) {
						continue;
					}

					if ( entry.target === this && entry.isIntersecting ) {
						if ( this._didInitMap ) {
							return;
						}

						// Any errors that happen below are fatal and cannot be retried.
						// No point to set this to true after actually creating a map.
						this._didInitMap = true;

						if ( this._observer ) {
							this._observer.disconnect();
							this._observer = null;
						}

						this.initMap();
					}
				}
			} );

			this._observer.observe( this );
		} );
	}

	disconnectedCallback() {
		if ( this._observer ) {
			this._observer.disconnect();
			this._observer = null;
		}

		if ( this._map ) {
			this._map.remove();
		}

		this._didInitMap = false;
	}

	async initMap() {
		try {
			const longitude = this.getAttribute( 'longitude' );
			const latitude = this.getAttribute( 'latitude' );

			if ( !longitude || !latitude ) {
				console.log( 'mr-map : no coordinates' );

				return;
			}

			const mapContainer = this.querySelector( '.js-locations-map' );
			if ( !mapContainer ) {
				console.log( 'mr-map : no map container' );

				return;
			}

			await Mapbox.loadDependencies();
			window.mapboxgl.accessToken = 'pk.eyJ1IjoibXJoZW5yeS1jYXciLCJhIjoiY2swMmZqaHQxMWtuOTNocHJvZjE4NmNuaCJ9.B-z_srcerjH1h7vPiuPRDQ';

			const coords = [
				longitude,
				latitude,
			];

			const map = await Mapbox.initializeMap( mapContainer, coords );
			map.dragRotate.disable();
			map.touchZoomRotate.disableRotation();
			map.scrollZoom.disable();

			const nav = new window.mapboxgl.NavigationControl( {
				showCompass: false,
				showZoom: true,
			} );

			map.addControl( nav, 'top-left' );
			const markers = [
				{
					latitude: parseFloat( latitude ),
					longitude: parseFloat( longitude ),
				},
			];

			const geoJSON = Mapbox.buildGeoJSON( markers );
			Mapbox.renderMarkers( geoJSON, map );

			this._map = map;

		} catch ( err ) {
			console.warn( err );

			const mapContainer = this.querySelector( '.js-locations-map' );
			if ( !mapContainer ) {
				return;
			}

			mapContainer.innerHTML = '<span style="user-select:none;"><br>&nbsp;&nbsp;❗️</span>';
		}
	}
}

customElements.define( 'mr-map', MrMap );
