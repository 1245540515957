interface ElementWithValue extends HTMLElement {
	value: { toString(): string };
	id: string;
}

function isElementWithValue( x: EventTarget | null ): x is ElementWithValue {
	if ( !x ) {
		return false;
	}

	const y = x as ElementWithValue;

	return y.id !== undefined && y.value !== undefined && ( typeof y.value.toString === 'function' );
}

export class MrDisplayValue extends HTMLElement {
	static get observedAttributes(): Array<string> {
		return [
			'disabled',
			'data-for',
		];
	}

	// Event Handlers
	#changeHandler = ( e: Event ): void => {
		if ( this.disabled ) {
			return;
		}

		const target = e.target;
		if ( !isElementWithValue( target ) ) {
			return;
		}

		if ( target.id !== this.getAttribute( 'data-for' ) ) {
			return;
		}

		this.innerText = this.forEl?.value.toString() ?? '';
	};

	// Life cycle
	connectedCallback(): void {
		this._addEventListeners();

		requestAnimationFrame( () => {
			this.innerText = this.forEl?.value.toString() ?? '';
		} );
	}

	disconnectedCallback(): void {
		this._removeEventListeners();
	}

	// Attributes
	override setAttribute( attr: string, value: string ): void {
		if ( this.disabled ) {
			return;
		}

		super.setAttribute( attr, value );
	}

	override removeAttribute( attr: string ): void {
		if ( this.disabled && 'disabled' !== attr ) {
			return;
		}

		super.removeAttribute( attr );
	}

	get disabled(): boolean {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( value: boolean ) {
		if ( value ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	attributeChangedCallback( attrName: string, oldVal: string, newVal: string ): void {
		if ( 'disabled' === attrName ) {
			this._removeEventListeners();
			if ( null === newVal ) {
				this._addEventListeners();
			}

			return;
		}

		if ( 'data-for' === attrName ) {
			this._removeEventListeners();
			this._addEventListeners();

			return;
		}
	}

	_addEventListeners(): void {
		this.forEl?.addEventListener( 'change', this.#changeHandler );
		document.addEventListener( 'change', this.#changeHandler );
	}

	_removeEventListeners(): void {
		this.forEl?.removeEventListener( 'change', this.#changeHandler );
		document.removeEventListener( 'change', this.#changeHandler );
	}

	get forEl(): ElementWithValue|null {
		const forID = this.getAttribute( 'data-for' );
		if ( !forID ) {
			return null;
		}

		const el = document.getElementById( forID );
		if ( !el ) {
			return null;
		}
		if ( !isElementWithValue( el ) ) {
			return null;
		}

		return el;
	}
}
