export default function storee( options: {
	store?: Storage | null,
} = {} ): {
	setItem: ( key: string, value: unknown ) => void
	getItem: ( key: string ) => unknown
	removeItem: ( key: string ) => void
	clear: () => void
} {
	if ( 'undefined' !== typeof options && 'object' !== typeof options ) {
		throw new Error( 'invalid options' );
	}

	const store = validatedStore( options.store );

	const setItem = ( key: string, value: unknown ): void => {
		store.setItem( key, JSON.stringify( value ) );
	};

	const getItem = ( key: string ) : unknown => {
		const rawValue = store.getItem( key );
		if ( !rawValue ) {
			return rawValue;
		}

		return JSON.parse( rawValue );
	};

	const removeItem = ( key: string ) => {
		store.removeItem( key );
	};

	const clear = () => {
		store.clear();
	};

	return {
		setItem,
		getItem,
		removeItem,
		clear,
	};
}

class MemoizeStore {
	_memoized: Record<string, string> = {};

	constructor() {
		this._memoized = {};
	}

	setItem( key: string, value: string ) {
		this._memoized[key] = value;
	}

	getItem( key: string ): string|null {
		return this._memoized[key];
	}

	removeItem( key: string ) {
		delete this._memoized[key];
	}

	clear() {
		this._memoized = {};
	}

	key( n: number ): string { // eslint-disable-line @typescript-eslint/no-unused-vars
		throw Error( 'unimplemented' );
	}

	get length(): number {
		return Object.keys( this._memoized ).length;
	}
}

const validatedStore = ( candidateStore: Storage|undefined|null ) => {
	let store = candidateStore;
	if ( !store ) {
		try {
			store = window.localStorage;
		} catch ( e ) {
			console.log( e );
		}
	}

	if ( !store ) {
		store = new MemoizeStore();
	}

	if ( !storeImplementsCorrectInterface( store ) ) {
		console.warn( 'invalid store' );
		store = new MemoizeStore();
	}

	if ( !storeWorks( store ) ) {
		console.warn( 'inoperable store' );
		store = new MemoizeStore();
	}

	return store;
};

const storeWorks = ( candidateStore: Storage ) => {
	const key = 'storee--test-key';
	const value = 'storee--test-value';
	let fetchedValue: string|null = '';
	let fetchedValueAfterRemove: string|null = '';

	try {
		candidateStore.setItem( key, value );
		fetchedValue = candidateStore.getItem( key );
		candidateStore.removeItem( key );
		fetchedValueAfterRemove = candidateStore.getItem( key );
	} catch ( e ) {
		console.warn( e );
	}

	if ( value !== fetchedValue ) {
		console.log( value, fetchedValue );

		return false;
	}

	if ( value === fetchedValueAfterRemove ) {
		console.log( value, fetchedValueAfterRemove );

		return false;
	}

	return true;
};

const storeImplementsCorrectInterface = ( candidateStore: Storage ) => {
	if ( 'object' !== typeof candidateStore ) {
		return false;
	}

	if ( 'function' !== typeof candidateStore.setItem ) {
		return false;
	}

	if ( 'function' !== typeof candidateStore.getItem ) {
		return false;
	}

	if ( 'function' !== typeof candidateStore.removeItem ) {
		return false;
	}

	if ( 'function' !== typeof candidateStore.clear ) {
		return false;
	}

	return true;
};
