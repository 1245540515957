export default class Mapbox {
	static loadDependencies() {
		const mapboxVersion = 'v1.13.3'; // previously v1.1.0

		const loadJS = function() {
			return new Promise( ( resolve, reject ) => {
				const script = document.createElement( 'script' );
				script.src = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.js`;

				script.onload = () => {
					resolve();

					return;
				};

				script.onerror = () => {
					reject( new Error( 'mapbox gl js failed to load' ) );

					return;
				};

				script.async = true;

				const first = document.head.getElementsByTagName( 'script' )[0];
				first.parentNode.insertBefore( script, first );
			} );
		};

		const loadCSS = function() {
			return new Promise( ( resolve, reject ) => {
				const link = document.createElement( 'link' );
				link.rel = 'stylesheet';
				link.href = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.css`;

				link.onload = () => {
					resolve();

					return;
				};

				link.onerror = () => {
					reject( new Error( 'mapbox gl css failed to load' ) );

					return;
				};

				const first = document.head.getElementsByTagName( 'link' )[0];
				first.parentNode.insertBefore( link, first );
			} );
		};

		return Promise.all( [
			loadJS(),
			loadCSS(),
		] );
	}

	static initializeMap( node, center ) {
		return new Promise( ( resolve ) => {
			const map = new window.mapboxgl.Map( {
				container: node,
				style: 'mapbox://styles/mrhenry-caw/ck02fr9v23gtu1clka6eor9is?optimize=true',
				center: center,
				zoom: 10,
				maxBounds: [
					[
						0,
						47,
					], // Southwest coordinates
					[
						9,
						54,
					], // Northeast coordinates
				],
			} );

			map.on( 'load', () => {
				return resolve( map );
			} );
		} );
	}

	static buildGeoJSON( locations ) {
		return {
			type: 'FeatureCollection',
			features: Array.from( locations, ( location ) => {
				const latitude = parseFloat( location.latitude );
				const longitude = parseFloat( location.longitude );

				if ( Number.isNaN( latitude ) || Number.isNaN( longitude ) ) {
					return false;
				}

				return {
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [
							longitude,
							latitude,
						],
					},
					properties: location.properties || {},
				};
			} ).filter( ( location ) => {
				return !!location;
			} ),
		};
	}

	static renderMarkers( geoJSON, map ) {
		const source = map.getSource( 'markers' );

		if ( source ) {
			source.setData( geoJSON );
		} else {
			map.addSource( 'markers', {
				type: 'geojson',
				data: geoJSON,
			} );

			map.addLayer( {
				id: 'markers',
				type: 'circle',
				source: 'markers',
				paint: {
					'circle-color': '#d70071',
					'circle-radius': 10,
				},
			} );
		}
	}

	static renderUserPosition( latitude, longitude, map ) {
		map.addLayer( {
			id: 'user',
			type: 'circle',
			source: {
				type: 'geojson',
				data: {
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [
							longitude,
							latitude,
						],
					},
				},
			},
			paint: {
				'circle-color': '#1b5a9a',
				'circle-radius': 10,
			},
		} );

		map.flyTo( {
			center: [
				longitude,
				latitude,
			],
			zoom: 9,
			bearing: 0,
			speed: 1,
		} );

		return map;
	}
}
