import { MrDialog } from '@mrhenry/wp--mr-interactive';
import store from '../helpers/store';

const GDPR_COOKIE_ACCEPTED = 'GDPR_COOKIE_ACCEPTED';
const GDPR_COOKIE_ACCEPTED_AT = 'GDPR_COOKIE_ACCEPTED_AT'; // No idea why we store this, must be legacy
const GDPR_COOKIE_EXPIRES_AT = 'GDPR_COOKIE_EXPIRES_AT';

function cookiesAccepted() {
	const accepted = true === store.getItem( GDPR_COOKIE_ACCEPTED );

	if ( !accepted ) {
		return false;
	}

	const expiresAtStr = store.getItem( GDPR_COOKIE_EXPIRES_AT );
	if ( !expiresAtStr || typeof expiresAtStr !== 'string' ) {
		return false;
	}

	const now = new Date();
	const expiresAt = new Date( expiresAtStr );

	const nowMinusExpiration = now.getTime() - expiresAt.getTime();
	if ( accepted && ( 0 > nowMinusExpiration ) ) {
		return true;
	}

	return false;
}

class MrGDPR_Cookie extends MrDialog {
	override connectedCallback() {
		super.connectedCallback();

		requestAnimationFrame( () => {
			if ( !cookiesAccepted() ) {
				setTimeout( () => {
					this.updateState( 'open' );
				}, 500 );

				return;
			}
		} );
	}

	override async updateState( directive: string ) {
		if ( 'close-and-accept' === directive ) {
			this.setAsSeen();

			super.updateState( 'close' );

			return;
		}

		super.updateState( directive );
	}

	setAsSeen() {
		const duration = 90 * 24 * 3600 * 1000; // 90 days in milliseconds
		const expiration = new Date();
		expiration.setTime( expiration.getTime() + duration );

		store.setItem( GDPR_COOKIE_ACCEPTED, true );
		store.setItem( GDPR_COOKIE_ACCEPTED_AT, new Date() );
		store.setItem( GDPR_COOKIE_EXPIRES_AT, expiration );

		window.dispatchEvent( new CustomEvent( 'gdpr-cookie:accepted', {
			detail: {},
			bubbles: true,
			cancelable: true,
		} ) );
	}

	override firstFocusableElement(): HTMLElement | void {
		return;
	}
}

customElements.define( 'mr-gdpr-cookie', MrGDPR_Cookie );

class MrGDPR_RequireCookies extends HTMLElement {
	connectedCallback() {
		this.render();
	}

	async render() {
		if ( !cookiesAccepted() ) {
			await new Promise<void>( ( resolve ) => {
				window.addEventListener(
					'gdpr-cookie:accepted',
					() => {
						return resolve();
					},
					{
						once: true,
					}
				);
			} );
		}

		const template = document.querySelector<HTMLTemplateElement>( 'template.tpl-gdpr-require-cookies' );
		if ( !template ) {
			return;
		}

		const clone = document.importNode( template.content, true );
		this.innerHTML = '';
		this.appendChild( clone );
	}
}

customElements.define( 'mr-gdpr-require-cookies', MrGDPR_RequireCookies );
