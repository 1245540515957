import { defineCustomElement, BaseController, parseHTML, renderNodes } from '@mrhenry/wp--custom-elements-helpers';
import dynamicProperties from '@mrhenry/wp--dynamic-properties';

defineCustomElement( 'mr-load-more', {
	attributes: [
		{
			attribute: 'infinite-scroll',
			type: 'bool',
		},
	],
	controller: class extends BaseController {
		init() {
			this.dimensions = dynamicProperties( {
				treshold: () => {
					return Math.round( window.innerHeight * 1.5 );
				},
			} );

			this.elements = this.elements || {};

			this.elements.target = this.el.querySelector( '.js-load-more-target' );
			this.elements.trigger = this.el.querySelector( '.js-load-more-trigger' );

			return this;
		}

		bind() {
			if ( this.infiniteScroll ) {
				this.on( 'scroll', () => {
					if ( !this.isBusy && this.elements.trigger ) {
						const {
							top,
						} = this.elements.trigger.getBoundingClientRect();

						if ( top < this.dimensions.treshold ) {
							this.fetch();
						}
					}
				}, window, {
					passive: true,
				} );
			}

			if ( this.elements.trigger ) {
				this.on( 'click', ( e ) => {
					e.preventDefault();
					e.stopImmediatePropagation();

					if ( !this.isBusy && this.elements.trigger && !this.infiniteScroll ) {
						const {
							top,
						} = this.elements.trigger.getBoundingClientRect();

						if ( top < this.dimensions.treshold ) {
							this.fetch();
						}
					}
				}, this.elements.trigger );
			}

			return this;
		}

		fetch() {
			this.isBusy = true;

			const label = this.elements.trigger.textContent;

			this.elements.trigger.textContent = 'Laden…';

			// Fetch
			fetch( this.elements.trigger.href,
				{
					credentials: 'same-origin',
				} )
				.then( ( res ) => {
					return res.text();
				} ).then( ( html ) => {
					const {
						content: parsed,
					} = parseHTML( html );

					const content = parsed.querySelector( '.js-load-more-target' );
					const trigger = parsed.querySelector( '.js-load-more-trigger' );

					if ( trigger ) {
						this.elements.trigger.href = trigger.href;
						this.elements.trigger.textContent = label;
					} else {
						this.elements.trigger.classList.add( 'button--disabled' );
						this.elements.trigger.textContent = 'Alles geladen';
						this.off( 'scroll', window );
					}

					if ( content ) {
						const fragment = document.createDocumentFragment();
						renderNodes( content, fragment );
						this.elements.target.appendChild( fragment );
					}

					this.isBusy = false;
				}, () => {
					this.isBusy = false;
				} );
		}
	},
} );
