import Mapbox from '../helpers/mapbox';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

class MrLocationsMap extends HTMLElement {
	// Life cycle
	connectedCallback() {
		if ( !( 'IntersectionObserver' in window ) ) {
			return;
		}

		// Wait for first (or next) frame render
		window.requestAnimationFrame( () => {
			this.locations = [];

			this.elements = {};
			this.elements.userLocationButton = document.querySelector( '.js-locations-user' );

			this._observer = new IntersectionObserver( ( entries ) => {
				for ( let i = 0; i < entries.length; i += 1 ) {
					const entry = entries[i];

					if ( !entry || !entry.target ) {
						continue;
					}

					if ( entry.target === this && entry.isIntersecting ) {
						if ( this._didInitMap ) {
							return;
						}

						// Any errors that happen below are fatal and cannot be retried.
						// No point to set this to true after actually creating a map.
						this._didInitMap = true;

						if ( this._observer ) {
							this._observer.disconnect();
							this._observer = null;
						}

						this.initMap();
						break;
					}
				}
			} );

			this._observer.observe( this );
		} );
	}

	disconnectedCallback() {
		if ( this._observer ) {
			this._observer.disconnect();
			this._observer = null;
		}

		this.locations = [];
	}

	initMap() {
		Mapbox.loadDependencies().then( () => {
			this.trySetLocations();
		} ).catch( ( err ) => {
			console.warn( err );
			this.innerHTML = '<span style="user-select:none;"><br>&nbsp;&nbsp;❗️</span>';
		} );
	}

	trySetLocations() {
		try {
			const data = JSON.parse( this.getAttribute( 'locations' ) );
			this.locations = data;
		} catch ( err ) {
			console.dir( err );
			bugsnagClient.notify( err );
		}

		// XXX_SECRET
		window.mapboxgl.accessToken = 'pk.eyJ1IjoibXJoZW5yeS1jYXciLCJhIjoiY2swMmZqaHQxMWtuOTNocHJvZjE4NmNuaCJ9.B-z_srcerjH1h7vPiuPRDQ';

		this.bind();
		this.renderMap();
	}

	bind() {
		if ( 'geolocation' in navigator ) {
			if ( this.elements.userLocationButton ) {
				this.elements.userLocationButton.addEventListener( 'click', () => {
					this.elements.userLocationButton.textContent = 'Jouw locatie aan het bepalen…';

					navigator.geolocation.getCurrentPosition( ( {
						coords,
					} ) => {
						if ( this.elements.userLocationButton.parentNode ) {
							this.elements.userLocationButton.parentNode.removeChild( this.elements.userLocationButton );
						}

						const {
							latitude, longitude,
						} = coords;

						if ( latitude && longitude ) {
							Mapbox.renderUserPosition( latitude, longitude, this.map );
						}
					}, () => {
						this.elements.userLocationButton.textContent = 'Jouw locatie niet gevonden';
					} );
				} );
			}
		}
	}

	renderMap() {
		// Default center/zoom is Flanders
		let latitude = 51.0109;
		let longitude = 3.7265;

		const firstLocation = this.locations[0] || {};

		if ( firstLocation.coordinates && firstLocation.coordinates.longitude && firstLocation.coordinates.longitude ) {
			latitude = firstLocation.coordinates.latitude;
			longitude = firstLocation.coordinates.longitude;
		}

		Mapbox.initializeMap( this, [
			longitude,
			latitude,
		] ).then( ( map ) => {
			map.dragRotate.disable();
			map.touchZoomRotate.disableRotation();
			map.scrollZoom.disable();

			const nav = new window.mapboxgl.NavigationControl( {
				showCompass: false,
				showZoom: true,
			} );

			map.addControl( nav, 'top-left' );

			this.map = map;
		} ).then( () => {
			let validMarkers = false;

			const markers = this.locations.map( ( location ) => {
				const properties = {};

				// Make sure a location has coords before trying
				// to put it on the map
				if ( location.coordinates.latitude && location.coordinates.longitude ) {
					validMarkers = true;

					return {
						properties: properties,
						latitude: parseFloat( location.coordinates.latitude ),
						longitude: parseFloat( location.coordinates.longitude ),
					};
				}

				return null;
			} ).filter( ( location ) => {
				return !!location;
			} );

			if ( 0 < markers.length && true === validMarkers ) {
				// This way of finding the marker bounds only works because we're within Belgium
				const sortedByLatitude = markers.sort( ( a, b ) => {
					return a.latitude - b.latitude;
				} );
				const sortedByLongitude = markers.sort( ( a, b ) => {
					return a.longitude - b.longitude;
				} );
				const margin = 0.25;

				const southWest = [
					sortedByLongitude[0].longitude - margin,
					sortedByLatitude[sortedByLatitude.length - 1].latitude - margin,
				];

				const northEast = [
					sortedByLongitude[sortedByLongitude.length - 1].longitude + margin,
					sortedByLatitude[0].latitude + margin,
				];

				const geoJSON = Mapbox.buildGeoJSON( markers );
				Mapbox.renderMarkers( geoJSON, this.map );
				this.map.fitBounds( [
					southWest,
					northEast,
				], {
					padding: 5,
				} );
			} else {
				// If no locations are found,
				// fit bounds to show flanders
				this.map.fitBounds( [
					[
						2.426620,
						50.755955,
					],
					[
						5.889554,
						51.435012,
					],
				], {
					padding: 5,
				} );
				Mapbox.renderMarkers( Mapbox.buildGeoJSON( [] ), this.map );
			}
		} );
	}
}

customElements.define( 'mr-locations-map', MrLocationsMap );
