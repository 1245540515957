const blocklist = [
	'viewport',
];

interface ParsedMetaTag {
	name: string|null
	property: string|null
	content: string|null
}

export function parseMetaTag( tag: HTMLMetaElement ): ParsedMetaTag|null {
	const name = tag.getAttribute( 'name' );
	const property = tag.getAttribute( 'property' );
	const content = tag.getAttribute( 'content' );

	if ( !name && !property ) {
		return null;
	}

	if ( name && blocklist.includes( name ) ) {
		return null;
	}

	return {
		name: name,
		property: property,
		content: content,
	};
}

const parser = new DOMParser();

interface ParsedHTML {
	title: string|null
	content: Element
	meta: Array<ParsedMetaTag>
}

export function parseHTML( html:string, selector: null | string = null ): ParsedHTML {
	const parsed = parser.parseFromString( html, 'text/html' );
	const title = parsed.title;
	const head = parsed.head;
	let content: Element = parsed.body;

	if ( selector ) {
		const foundContent = content.querySelector( selector );

		if ( !foundContent ) {
			throw new Error( 'not-found' );
		}

		content = foundContent;
	}

	// Get document meta
	const meta: Array<ParsedMetaTag> = [];
	head.querySelectorAll( 'meta' ).forEach( ( metaEl ) => {
		const parsedMetaEl = parseMetaTag( metaEl );
		if ( parsedMetaEl ) {
			meta.push( parsedMetaEl );
		}
	} );

	return {
		title: title,
		content: content,
		meta: meta,
	};
}

export function renderNodes( content: Element|DocumentFragment, container: Element|DocumentFragment ): void {
	while ( container.firstChild ) {
		container.removeChild( container.firstChild );
	}

	const images = content.querySelectorAll( 'img' );

	images.forEach( ( img ) => {
		const clone = document.createElement( 'img' );

		const attributes = Array.from( img.attributes );

		attributes.forEach( ( attribute ) => {
			clone.setAttributeNode( attribute.cloneNode( true ) as Attr );
		} );

		if ( img.parentNode ) {
			img.parentNode.replaceChild( clone, img );
		}

		return clone;
	} );

	const videos = Array.from( content.querySelectorAll( 'video' ) );

	videos.forEach( ( video ) => {
		const clone = document.createElement( 'video' );

		const attributes = Array.from( video.attributes );

		attributes.forEach( ( attribute ) => {
			clone.setAttributeNode( attribute.cloneNode( true ) as Attr );
		} );

		if ( video.parentNode ) {
			video.parentNode.replaceChild( clone, video );
		}

		return clone;
	} );

	if ( content instanceof DocumentFragment ) {
		container.appendChild( content );
	} else {
		for ( let i = content.children.length - 1; 0 <= i; i -= 1 ) {
			const child = content.children[i];

			if ( container.firstChild ) {
				container.insertBefore( child, container.firstChild );
			} else {
				container.appendChild( child );
			}
		}
	}
}

export function cleanNodes( fromElement: Element, selector: string | Array<string> ): Element {
	if ( !selector || ( Array.isArray( selector ) && 0 === selector.length ) ) {
		return fromElement;
	}

	let stringSelector = '';
	if ( Array.isArray( selector ) ) {
		stringSelector = selector.join( ', ' );
	} else {
		stringSelector = selector;
	}

	const bloat = Array.from( fromElement.querySelectorAll( stringSelector ) );

	bloat.forEach( ( node ) => {
		if ( node.parentNode ) {
			node.parentNode.removeChild( node );
		}
	} );

	return fromElement;
}
