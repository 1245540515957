class MrRatingsForm extends HTMLElement {
	connectedCallback() {
		this.addEventListener( 'click', ( e ) => {
			if ( !e || !e.target || !( e.target instanceof HTMLElement ) ) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			const target = e.target.closest( 'button[data-value]' );
			if ( !target ) {
				return;
			}

			const value = target.getAttribute( 'data-value' );
			if ( !value ) {
				return;
			}

			switch ( value ) {
				case 'heel goed':
				case 'minder goed':
				case 'niet duidelijk':
					break;
				default:
					return;
			}

			const nonce = this.getAttribute( 'data-nonce' );
			if ( !nonce ) {
				return;
			}

			const id = this.getAttribute( 'data-id' );
			if ( !id ) {
				return;
			}

			fetch(
				'/wp-json/caw/rate-question/',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify( {
						_wp_http_referer: window.location.href,
						_wpnonce: nonce,
						ajax_submit: true,
						id: id,
						rating: value,
					} ),
				}
			).then( ( res ) => {
				if ( !res.ok ) {
					throw new Error( 'Failed to submit rating' );
				}
			} ).finally( () => {
				const successMessageArea = this.querySelector( '[data-success-message]' );
				if ( !successMessageArea ) {
					return;
				}

				const allButtons = this.querySelectorAll( 'button[data-value]' );
				allButtons.forEach( ( button ) => {
					button.remove();
				} );

				successMessageArea.innerHTML = '<p>Bedankt voor de feedback!</p>';
			} );
		} );
	}
}

customElements.define( 'mr-ratings-form', MrRatingsForm );
