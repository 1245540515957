class MrCopy extends HTMLElement {
	connectedCallback() {
		this.addEventListener( 'click', async( e ) => {
			if ( !e || !e.target ) {
				return;
			}

			e.preventDefault();

			const text = this.getAttribute( 'data-content' );
			if ( !text ) {
				return;
			}

			try {
				await navigator.clipboard.writeText( text );
			} catch ( error ) {
				console.error( error.message );
			}
		} );
	}
}

customElements.define( 'mr-copy', MrCopy );
